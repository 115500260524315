/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

import './js/page/front';
import './js/page/sidenav';
import './js/page/modal';
import './js/page/mediatheque';
import {Page} from "./js/common/Page/Page";
import {startSlider} from "./js/page/slider";
import $ from "jquery";
// import {checkContactInInfos, resizeContainer} from "./js/page/home/PageHome";


Page.initialize();
$(document).ready(() => {
    // resizeContainer()
    // checkContactInInfos()
    startSlider();
});

