import $ from 'jquery';

///***  MODAL  ***///

// Close modal on body click
$('body').on('click', function (e) {
    if ($('.page-registration').length) {
        if ((!$('.recap-block-participant-list-accompagnants').is(e.target) && $('.recap-block-participant-list-accompagnants').has(e.target).length === 0) && (!$('.recap-block-participant-list-parent').is(e.target) && $('.recap-block-participant-list-parent').has(e.target).length === 0)) {
            $('.recap-block-participant-wrapper').each(function () {
                if ($(this).hasClass('highlight')) {
                    $(this).removeClass('highlight')
                }
            })
        }


    }
    if ($('body').hasClass('show-modal')) {

        if ((!$('.modal-popin').is(e.target) && $('.modal-popin').has(e.target).length === 0) && !$('.modal-btn').is(e.target)) {
            $('body').removeClass('show-modal').find('.overlay-modal').hide();
            $('.modal-popin').hide();
        }
    }
});

// Open modal on btn modal click
$(document).on('click', '.modal-btn', function (e) {
    e.preventDefault()
    if (!$('.overlay-modal').length) {
        $('body').append('<div class="overlay-modal"></div>')
    }

    var modalClassToOpen = $(this).data('modal');

    $('body').addClass('show-modal').find('.overlay-modal').show();
    $('.modal-popin.' + modalClassToOpen).show().css("display", "flex");

});

// Close modal on close modal click
$(document).on('click', '.modal-popin-close', function (e) {
    e.preventDefault()
    $('body').removeClass('show-modal').find('.overlay-modal').hide();
    $(this).closest('.modal-popin').hide();
});


$(document).on('click', '.modal-popin .modal-popin-text a', function (e) {
    e.preventDefault()
    window.open($(this).attr('href'), '_blank');
});


